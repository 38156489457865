/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useReducer, useState  } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../filterMarcas/filterMarcas.css";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import {  Button, Form, Spinner, Alert } from "react-bootstrap";
import { formSolicitudDescuento } from "../service/loginCliente.service";
import {
  handleObtenerProvincia,
  handleObtenerDistrito,
  handleObtenerDepartamento,
  validarNumero,
} from "../service/general";
import {
  HttpStatus,
  localStoreEnum,
  Moneda,
  SUCCESS_SERVER,
  tipoActualizacionCotizacionDetalle,
  InfoCondicionCompra,
  FilterProducto,
  filterOrder,
  LOGGIN,
  UbigeoTipo,
  TypePresentacion,
} from "../service/ENUM";
import { findProductos } from "../service/producto.service";
import {
  registrarCotizacion,
  registrarCotizacionDetalle,
} from "../service/cotizacion.service";
import { handleSyncDatosCotizacion } from "../service/general";
import { Modal } from "react-bootstrap";
import ServerException from "../utils/serverException";

export default function ProductoDetalle(props) {
  
  const location = useLocation();
  const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} de ${month} de ${year}`;
  };
  const login = localStorage.getItem(localStoreEnum.ISLOGIN);
  const usu = (login && login == LOGGIN.LOGGIN) ? JSON.parse(localStorage.getItem(localStoreEnum.USUARIO)) : null;
  let params = useParams();
  const [selectedOption, setSelectedOption] = useState('');
  const [msgWsp, setMsgWsp] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showHomeDelivery, setShowHomeDelivery] = useState(false);
  const [showHomeDeliveryLocation, setShowHomeDeliveryLocation] = useState(false);
  const [showPickupStore, setShowPickupStore] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisponibilidadEnabled, setIsDisponibilidadEnabled] = useState(false);
  const [formData, setFormData] = useState({
    name: (login && login == LOGGIN.LOGGIN) ? usu.NombreCompleto: '',
    email: (login && login == LOGGIN.LOGGIN) ? usu.chrEmail:'',
    message:'',
    subject: 'Haznos tu oferta',
    productId: params.chrCodigoProducto??''
  });
  const [departamento, setDepartamento] = useState('15');
  const [provincia, setProvincia] = useState('');
  const [distrito, setDistrito] = useState('');
  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [distritoSeleccionado, setDistritoSeleccionado] = useState('');

const [errors, setErrors] = useState({ email: null, message: null});

const handleOptionChange = (e) => {
  setSelectedOption(e.target.value);
  setShowPickupStore(false);
};
const handleDepartamentoChange = async (e) => {
  setDepartamento(e.target.value);
  var provincias = await handleObtenerProvincia(e.target.value);
  setProvincias(provincias);
  setProvincia('');
  setDistrito('');
  setIsDisponibilidadEnabled(false);
};
const handleProvinciaChange = async (e) => {
  setProvincia(e.target.value);
  var distritos = await handleObtenerDistrito({chrCodigoDepartamento: departamento,chrCodigoProvincia:  e.target.value});
  setDistritos(distritos);
  setDistrito('');
  setIsDisponibilidadEnabled(false);

};

const eSimuladoDepartamento = {
  target: {
    value: '15'
  }
};

const eSimuladoProvincia = {
  target: {
    value: '01'
  }
};


const handleDistritoChange = async (e) => {

  setDistrito(e.target.value);
  setDistritoSeleccionado(e.target.options[e.target.selectedIndex].text);
  setIsDisponibilidadEnabled(true);
};

const handleShowWSP = () => {
  setShowWhatsapp(true);
  setMsgWsp('');
}
const handleShow = () => { 
  if(login && login == LOGGIN.LOGGIN){ setIsDisabled(false) ; setErrors({message: true}); }else{ setIsDisabled(true) ; setErrors({message: true});}
   
  setFormData({ name: (login && login == LOGGIN.LOGGIN) ? usu.NombreCompleto: '',
  email: (login && login == LOGGIN.LOGGIN) ? usu.chrEmail:'', 
  message: '', subject: 'Haznos tu oferta',productId:params.chrCodigoProducto??'' })
 
  setShow(true); 
};
const handleClose = () => {
  setShow(false);
  setIsSending(false);
  setErrorMessage('');
  setErrors({ email: '' , message: ''});
  setFormData({ name: '', email: '', message: '', subject: 'Haznos tu oferta',productId:params.chrCodigoProducto??'' });
};



const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }));
  console.log('validando')
  if (name === 'email') {
    validateEmail(value);
  }
  if (name === 'message') {
    validateImporte(value);
  }
  console.log("errors",errors)
};

const validateImporte = (email) => {
  const emailRegex = /^[0-9]+(\.[0-9]+)?$/;
  if (!emailRegex.test(email)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      message: 'Porfavor ingrese un monto válido'
    }));
  } else {
    setErrors((prevErrors) => ({
      ...prevErrors,
      message: false
    }));
  }
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: 'Por favor ingrese un correo electrónico válido.'
    }));
  } else {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: false
    }));
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!errors.email && !errors.message) {
    setIsSending(true);
    // Simula un envío de formulario asíncrono
    try {
      const response = await formSolicitudDescuento(formData);
      if (response.status === HttpStatus.HttpStatus_OK) {
        setShowSuccess(true);
      }else{
        setShowError(true);
      }
      
      handleClose();
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      // Aquí puedes manejar el error, mostrar un mensaje al usuario, etc.
      setShowError(true);
      setErrorMessage('Error al enviar el formulario. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setIsSending(false);
    }
  }
};


  let history = useHistory();
  const producto = {
    chrCodigoProducto: "",
    numValorVentaDolar: "",
    numValorVentaSoles: "",
    numValorVentaDolarIgv: "",
    numValorVentaRefDolar: "",
    numValorVentaRefSoles: "",
    numValorVentaSolesIgv: "",
    typePresentacion:null,
    typePres : "",
    numValorDesc: 0,
    numValorVentaSoles: "",
    numCodigoMoneda: props.moneda.numCodigoMoneda,
    vchDescripcion: "",
    vchDescripcionSmall: "",
    numStock: 0,
    numOutlet: 0,
    numProductoVigencia: 0,
    vchAnios: "",
    vchModelos: "",
    vchMotores: "",
    familia: {
      chrCodigoFamilia: "",
      vchDescripcion: "",
    },
    /*Url de la imagen a mostrar en la lista de productos */
    imagenDefault: {
      numCodigoProductoIimagen: "",
      chrCodigoProducto: "",
      chrSrcImagen: "",
      chrNombre: "",
      chrType: "",
    },
    listaProductoImagen: [],
    listaProductoDetalle: [],
  };
  const cotizacionResumen = {
    totalRegistros: 0,
    numSubTotalDol: 0,
    numIgvDol: 0,
    numEnvioDol: 0,
    numTotalDol: 0,
    numSubTotalSol: 0,
    numIgvSol: 0,
    numEnvioSol: 0,
    numTotalSol: 0,
    cantidadDetalleSeleccionado: 0,
  };
  const [state, dispatch] = useReducer(reducer, {
    cantidad: 1,
    producto: producto,
    cotizacionResumen: cotizacionResumen,
    showModal: false,
    mensajeStock: "",
    shareFacebook: "",
    shareTwitter: "",
    server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_DEFAULT },
    zoom: false,
    image:{chrSrcImagen:null,chrNombre:null}
  });

  const handleChangeWSP = (e) => {
    if(e.target.value === ''){
      setMsgWsp('');
    }else{
      setMsgWsp(encodeURIComponent('Hola me interesa el articulo'+ state.producto?.vchDescripcion +'el precio no se acomoda a mi presupuesto y estoy dispuesto a pagar la suma de USD$ '+ e.target.value + ' Inc Igv'))
    }
    
  };

  async function handleServicioBuscarProductos(
    chrCodigoProducto,
    _filterOrder
  ) {
    const rpt = await findProductos({
      chrCodigoFamilia: null,
      vchDescripcion: null,
      chrCodigoProducto: chrCodigoProducto,
      filterProducto: FilterProducto.FILTER_CODIGO,
      filterOrder: _filterOrder,
      pagina: 1,
      limit: 1,
    });
    let shareFacebook = "";
    let shareTwitter = "";
    if (rpt.status === HttpStatus.HttpStatus_OK) {
      const json = await rpt.json();
      console.log(json);
      if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_OK) {
        for (let index = 0; index < json.listaProductos.length; index++) {
          let e = json.listaProductos[index];

          let _listaProductoImagen = [];
          /*Lista de imagenes del producto */
          for (let i = 0; i < e.listaProductoImagen.length; i++) {
            let obj = e.listaProductoImagen[i];
            _listaProductoImagen.push(
              <div
                key={i}
                // eslint-disable-next-line no-loop-func
                onClick={(e) => handleEventZoom(obj)}
              >
                <img
                  className="detalle-img"
                  key={i}
                  src={"data:image/png;base64," + obj.chrSrcImagen}
                  alt={obj.chrNombre}
                  title={obj.chrNombre}
                ></img>
              </div>
            );
          }
          /*Lista de detalles del producto */
          let _listaProductoDetalle = [];

          for (let i = 0; i < e.listaProductoDetalle.length; i++) {
            let objDet = e.listaProductoDetalle[i];
            if (objDet.rowTipo === 1) {
              _listaProductoDetalle.push(
                <div className="detalle" key={i}>
                  <div className="desc-label">{objDet.titulo}</div>
                  <div
                    className={
                      objDet.titulo === "Código"
                        ? "desc-value desc-value-resaltar"
                        : objDet.titulo === "Marca"
                        ? "desc-value  desc-value-resaltar-codigo"
                        : "desc-value"
                    }
                  >
                    {objDet.descripcion}
                  </div>
                </div>
              );
            }
          }
          /*Ficha Tecnica */
          _listaProductoDetalle.push(
            <div className="detalle detalle-space" key={"-3"}></div>
          );
          /*  _listaProductoDetalle.push(
            <div className="detalle" key={"-2"}>
              <div className="desc-label">Características del producto</div>
              <div className="desc-value"> </div>              
            </div>
          );
          
          for (let i = 0; i < e.listaProductoDetalle.length; i++) {
            let objDet = e.listaProductoDetalle[i];
            if (objDet.rowTipo === 0) {
              _listaProductoDetalle.push(
                <div className="detalle" key={i}>
                  <div className="desc-label desc-row-ref">{objDet.titulo}</div>
                  <div className="desc-value desc-row-ref">
                    {objDet.descripcion}
                  </div>
                </div>
              );
            }
          }*/
          
          producto.numUnspsc = e.numUnspsc
          producto.chrCodigoProducto = e.chrCodigoProducto;
          producto.numValorVentaDolar = e.numValorVentaDolar;
          producto.numValorVentaSoles = e.numValorVentaSoles;
          producto.numValorVentaDolarIgv = e.numValorVentaDolarIgv;
          producto.numValorVentaSolesIgv = e.numValorVentaSolesIgv;
          producto.numCodigoMoneda = props.moneda.numCodigoMoneda;
          producto.vchDescripcion = e.vchDescripcion;
          producto.vchDescripcionSmall = e.vchDescripcionSmall;
          producto.numValorVentaRefDolar = e.numValorVentaRefDolar;
          producto.numValorVentaRefSoles = e.numValorVentaRefSoles;
          producto.numValorDesc = e.numValorDesc;
          producto.vchModelos = e.vchModelos;
          producto.vchMotores = e.vchMotores;
          producto.numStock = e.numStock;
          producto.typePresentacion = e.typePresentacion; 
          producto.familia.chrCodigoFamilia = e.familia.chrCodigoFamilia;
          producto.familia.vchDescripcion = e.familia.vchDescripcion;
          producto.numOutlet = e.numOutlet;
          producto.numProductoVigencia = json.vigencia.numProductoVigencia;
          /*Url de la imagen a mostrar en la lista de productos */
          producto.imagenDefault.numCodigoProductoIimagen =
            e.imagenDefault.numCodigoProductoIimagen;
          producto.imagenDefault.chrCodigoProducto =
            e.imagenDefault.chrCodigoProducto;
          producto.imagenDefault.chrSrcImagen =
            "data:image/png;base64," + e.imagenDefault.chrSrcImagen;
          producto.imagenDefault.chrNombre = e.imagenDefault.chrNombre;
          producto.imagenDefault.chrType = e.imagenDefault.chrType;
          producto.listaProductoImagen = _listaProductoImagen;
          producto.listaProductoDetalle = _listaProductoDetalle;
          shareFacebook =
            "https://www.facebook.com/sharer/sharer.php?u=https://subaruparts.eanet.pe/subaruparts/detalle/" +
            producto.familia.chrCodigoFamilia +
            "/" +
            producto.familia.vchDescripcion +
            "/" +
            producto.chrCodigoProducto +
            "&quote=" +
            producto.vchDescripcion;
          shareTwitter =
            "https://twitter.com/intent/tweet?url=https://subaruparts.eanet.pe/subaruparts/detalle/" +
            producto.familia.chrCodigoFamilia +
            "/" +
            producto.familia.vchDescripcion +
            "/" +
            producto.chrCodigoProducto +
            "&text=" +
            producto.vchDescripcion;
        }

        dispatch({
          type: actionType.LOAD_PRODUCTOS,
          producto: producto,
          shareFacebook: shareFacebook,
          shareTwitter: shareTwitter,
          server: {
            error: "",
            success: SUCCESS_SERVER.SUCCES_SERVER_DEFAULT,
          },
        });
      }
      if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_INFO) {
        dispatch({
          type: actionType.LOAD_PRODUCTOS,
          producto: producto,
          shareFacebook: shareFacebook,
          shareTwitter: shareTwitter,
          server: {
            error: json.response.error,
            success: SUCCESS_SERVER.SUCCES_SERVER_INFO,
          },
        });
      }
    } else {
      dispatch({
        type: actionType.LOAD_PRODUCTOS,
        producto: producto,
        shareFacebook: shareFacebook,
        shareTwitter: shareTwitter,
        server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_ERROR },
      });
    }
  }
  //eslint-disable-next-line
  let _chrCodigoFamilia = params.chrCodigoFamilia;
  let _chrCodigoProducto = params.chrCodigoProducto;
  let _filterOrder = filterOrder.FilterAscDescripcion;
  if (_chrCodigoFamilia === "outl") {
    _filterOrder = filterOrder.FilterOutlet;
    _chrCodigoFamilia = "110A";
  }
  //eslint-disable-next-line
  useEffect(() => {
    //eslint-disable-next-line
    props.eventSelectMarca(_chrCodigoFamilia);
    //eslint-disable-next-line
    console.log("useEffect[ProductoDetalle] ProductoDetalle");
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    //eslint-disable-next-line
    handleServicioBuscarProductos(_chrCodigoProducto, _filterOrder);
    cargarDepartamentos();
    //eslint-disable-next-line
    console.log("useEffect[ProductoDetalle] handleServicioBuscarProductos");
    //eslint-disable-next-line
    if(location.state?.message){
      handleShow();
    }
       //eslint-disable-next-line
  }, [props.moneda.numCodigoMoneda]);

  async function cargarDepartamentos() {
    var deps =  await handleObtenerDepartamento();
    setDepartamentos(deps);
  }


  const handleEventClickregistrarCotizacion = async () => {
    if (state.cantidad <= state.producto.numStock) {
      let cotizacion = handleSyncDatosCotizacion();
      const rpt = await registrarCotizacion(cotizacion);
      if (rpt.status === HttpStatus.HttpStatus_OK) {
        const json = await rpt.json();
        if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_OK) {
          cotizacion.numCodigoCotizacionOnline = json.numCodigoCotizacionOnline;
          cotizacion.numCodigoCliente = json.numCodigoCliente;
          cotizacion.numCodigoClienteUsuario = json.numCodigoClienteUsuario;
          localStorage.setItem(
            localStoreEnum.COTIZACION,
            JSON.stringify(cotizacion)
          );
          /*Registro de cotizacion detalle */
          let cotizacionDetalleRequest = {
            numCodigoCotizacionOnline: cotizacion.numCodigoCotizacionOnline,
            producto: {
              chrCodigoProducto: state.producto.chrCodigoProducto,
              numOutlet: state.producto.numOutlet,
              numProductoVigencia:state.producto.numProductoVigencia
            },
            numCantidad: state.cantidad,
            tipoActualizacionCotizacionDetalle:
              tipoActualizacionCotizacionDetalle.ADICIONAR,
          };
          const rptDetalle = await registrarCotizacionDetalle(
            cotizacionDetalleRequest
          );
          if (rptDetalle.status === HttpStatus.HttpStatus_OK) {
            const jsonDetalle = await rptDetalle.json();
            if (
              jsonDetalle.response.status === SUCCESS_SERVER.SUCCES_SERVER_OK
            ) {
              //console.log(jsonDetalle);
              cotizacionResumen.numSubTotalDol = jsonDetalle.numSubTotalDol;
              cotizacionResumen.numIgvDol = jsonDetalle.numIgvDol;
              cotizacionResumen.numEnvioDol = jsonDetalle.numEnvioDol;
              cotizacionResumen.numTotalDol = jsonDetalle.numTotalDol;
              cotizacionResumen.numSubTotalSol = jsonDetalle.numSubTotalSol;
              cotizacionResumen.numIgvSol = jsonDetalle.numIgvSol;
              cotizacionResumen.numEnvioSol = jsonDetalle.numEnvioSol;
              cotizacionResumen.numTotalSol = jsonDetalle.numTotalSol;
              cotizacionResumen.totalRegistros = jsonDetalle.totalRegistros;
              cotizacionResumen.cantidadDetalleSeleccionado =
                jsonDetalle.cantidadDetalleSeleccionado;
              dispatch({
                type: actionType.SHOW,
                showModal: true,
                cotizacionResumen: cotizacionResumen,
              });
            }
            //console.log(jsonDetalle);
            if (
              jsonDetalle.response.status === SUCCESS_SERVER.SUCCES_SERVER_INFO
            ) {

              dispatch({
                type: actionType.SET_CANTIDAD_STOCK,
                cantidad: state.producto.cantidad,
                mensajeStock:jsonDetalle.response.error,
              });/*
              dispatch({
                type: actionType.ERROR,
                server: {
                  error: jsonDetalle.response.error,
                  success: SUCCESS_SERVER.SUCCES_SERVER_INFO,
                },
              });*/
            }
          } else {
            dispatch({
              type: actionType.ERROR,
              server: {
                error: "",
                success: SUCCESS_SERVER.SUCCES_SERVER_ERROR,
              },
            });
          }
          /*Registro de cotizacion detalle */
        }
        if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_INFO) {
          dispatch({
            type: actionType.ERROR,
            server: {
              error: json.response.error,
              success: SUCCESS_SERVER.SUCCES_SERVER_INFO,
            },
          });
        }
      } else {
        dispatch({
          type: actionType.ERROR,
          server: {
            error: "",
            success: SUCCESS_SERVER.SUCCES_SERVER_ERROR,
          },
        });
      }
    } else {
      dispatch({
        type: actionType.SET_CANTIDAD_STOCK,
        cantidad: state.producto.cantidad,
        mensajeStock:
          "Disculpe las molestias, el stock disponible para este producto es de " +
          state.producto.numStock +
          " unidades.",
      });
    }
  };

  function handleInvitado(){
    setIsDisabled(false);
  }
  function handleEventCloseModal() {
    if(state.producto.typePresentacion === TypePresentacion.TypeRemate){
      dispatch({
        type: actionType.SHOW,
        cotizacionResumen: cotizacionResumen,
        showModal: false,
      });
      history.push("/ocasion");
      window.location.reload();
    }else{
      dispatch({
        type: actionType.SHOW,
        cotizacionResumen: cotizacionResumen,
        showModal: false,
      });
      history.push("/shop");
      window.location.reload();
    }

  }
  function handleEventGoCaja() {
    history.push("/carrito");
    window.location.reload();
  }
  //const tooglesGroupId = 'Toggles';
  //const valuesGroupId = 'Values';
  function handleEventZoom(_obj) {
    dispatch({ type: actionType.ZOOM_OBJECT, zoom: true, image:{chrSrcImagen:_obj.chrSrcImagen,chrNombre:_obj.chrNombre}});
  }
  return (
    <div
      className="producto-det"
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          dispatch({ type: actionType.ZOOM, zoom: false });
        }
      }}
    >
      {state.zoom === true ? (
        <div className="producto-zoom">
          <div className="producto-zoom-content">
            <div className="producto-zoom-header">
              <i
                className="fa fa-close"
                onClick={() => dispatch({ type: actionType.ZOOM, zoom: false })}
              ></i>
            </div>
            <img
              className="img-zoom"
              src={"data:image/png;base64," + state.image.chrSrcImagen}
              alt={state.image.chrNombre}
              title={state.image.chrNombre}
            ></img>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="producto-det-link">
        <Link to={"/shop?descripcion=" + state.producto.familia.vchDescripcion}>
          Inicio
        </Link>
        <span className="producto-det-link-span">/</span>
        <span className="producto-det-link-nombre">
          {state.producto.typePresentacion == TypePresentacion.TypeRemate ? <Link to={"/ocasion"}>Ocasión</Link> : state.producto.familia.vchDescripcion}
        </span>
        { state.producto.typePresentacion == TypePresentacion.TypeRemate ?
        <> <Link style={{float: 'right'}} to={"/ocasion"}> Atrás <i className="fa fa-arrow-left" style={{ marginRight: '5px' }}></i></Link></> :<></>}
       
      </div>

      <div className="producto-det-row">
        <div className="producto-det-row1 ">
          <div className="prod-det-carrousel">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={4000}
              showThumbs={true}
              showStatus={false}
              autoFocus={true}
              showIndicators={false}
              dynamicHeight={true}
            >
              {state.producto.listaProductoImagen}
            </Carousel>
          </div>
          <div className="prod-det-carrousel-text">Imagen referencial</div>
        </div>
        <div className="as">
          <div className="producto-det-row3">
            <span>{state.producto.vchDescripcion}</span>
          </div>
          <div className="d-flex flex-wrap">
            <div className="producto-det-row2">
              <div className="producto-det-row2-espec-title">
                <span>Especificaciones Principales</span>
              </div>
              <div className="producto-det-row2-espec">
                <span>&bull; Marca Subaru</span>
                <br/>
                <span>&bull; Codigo de Repuesto {state.producto.chrCodigoProducto}</span>
                <br/>
                <span>&bull; Compatible {state.producto?.vchModelos?.replaceAll(",","/")}</span>
                <br/>
                <span>&bull; Compatible Motores {state.producto?.vchMotores?.replaceAll(",","/")}</span>
              </div>   
              <div className="producto-det-row2-delivery d-flex flex-row justify-content-between mt-4">
                <div className="d-flex flex-column w-25 ml-5" onClick={async () => {setShowHomeDelivery(true); await handleDepartamentoChange(eSimuladoDepartamento);await handleProvinciaChange(eSimuladoProvincia); }}>
                <span>Despacho a domicilio</span> 
                <img src="https://st2.depositphotos.com/4441075/6790/v/950/depositphotos_67905147-stock-illustration-home-delivery-web-icon.jpg" alt="wa2"/>
                </div>
                <div className="d-flex flex-column w-25 mr-5" onClick={() => setShowPickupStore(true)}>
                <span>Recojo en tienda</span> 
                <img src="/marcas/caja-del-paquete.png" style={{width:"70px", marginLeft:"1em"}} />
                </div>
              </div>   
              <div className="producto-det-row" style={{textAlign:"center"}}> 
              
                <div className="data-section" style={{width:"100%"}}>
                  <div className="data-body" style={{width:"100%"}}>
                <div className="data-list">
                {selectedOption == 'option1'&& <>
                  <div className="data-entity"  style={{display:"table-column"}} >
                    <p className="data-title">Av Republica de Panama 4259 - Surquillo</p>
                    <p className="data-subtitle" style={{marginLeft:"12px",marginTop:"-17px"}}>A partir del  {formatDate(addDays(new Date(), 3))}</p>
                    
                  </div></>
                  }
                  {selectedOption == 'option2'&& <>
                  <div className="data-entity"  style={{display:"table-column"}}>
                  <p className="data-title">Av Libertad 386 - San Miguel</p>
                  <p className="data-subtitle" style={{marginLeft:"12px",marginTop:"-17px"}}>A partir del {formatDate(addDays(new Date(), 4))}</p>
                  
                  </div>
                  </>
                  }
                </div>
                  </div>
                </div>
                </div> 
            </div>
            <div className="producto-det-row2">
              <div className="producto-det-row2-precio d-flex flex-wrap">
                { (parseFloat(state.producto.numValorDesc??0) < 100 && state.producto.typePresentacion ===  TypePresentacion.TypeRemate) && <>
                <div className="d-flex flex-column w-50"><span className="price-base">USD {state.producto.numValorVentaRefDolar}</span></div>
                <div className="d-flex flex-column w-50"><span className="price-base">PEN {state.producto.numValorVentaRefSoles}</span></div>
                <div className="d-flex flex-column w-100"><span className="price-discount">Precio Ocasión -{ (parseFloat(state.producto.numValorDesc??0) < 100) && state.producto.numValorDesc}% :</span></div>
                <div className="d-flex flex-column w-50"><span className="price-final" >USD {state.producto.numValorVentaDolarIgv}</span></div>
                <div className="d-flex flex-column w-50"><span className="price-final">PEN {state.producto.numValorVentaSolesIgv}</span></div>
                </>}
                {
                  (state.producto.typePresentacion !==  TypePresentacion.TypeRemate) ?
                  <>
                  <div className="d-flex flex-column w-50"><span  >USD {state.producto.numValorVentaDolarIgv}</span></div>
                <div className="d-flex flex-column w-50"><span >PEN {state.producto.numValorVentaSolesIgv}</span></div>
                </>:<></>
                }
               
              </div>
              <div>
              {(state.producto.typePresentacion ===  TypePresentacion.TypeRemate)?
              <>
              <div className="producto-det-row2-social">Si está interesado en este artículo pero el precio no se ajusta a su presupuesto, estamos dispuestos a recibir una propuesta de precio.</div>
              <div className="producto-det-row2-social"> Escríbenos por WhatsApp o correo para que nuestros asesores puedan evaluar su propuesta</div>
              <div className="producto-det-row2-social" style={{textAlign:"center", marginTop: "10px"}}>
                <a
                  className="btn"
                  onClick={handleShowWSP}
                  target="noreferrer"
                ><img src="/marcas/wsp_ico.jpg" width={45} height={45}></img></a>
                <a
                  className="btn"
                  onClick={handleShow}
                  target="noreferrer"
                ><img src="/marcas/mail_ico.png"  width={45} height={45}></img></a>
              </div></>
              :
              <>
              <div className="producto-det-row2-social">Compartir </div>
              <div className="producto-det-row2-social">
                <a
                  className="btn btn-social fa fa-facebook"
                  href={state.shareFacebook}
                  target="noreferrer"
                ></a>
                <a
                  className="btn btn-social fa fa-twitter"
                  href={state.shareTwitter}
                  target="noreferrer"
                ></a>
              </div>
              </>
              }

              </div>
              <div className="producto-det-row2-shop d-flex justify-content-center">
                <span className="d-none">Cantidad </span>
                <div className="producto-det-row2-shop-div">
                  <input 
                    type="number"
                    className="form-control d-none"
                    value={state.cantidad}
                    min={1}
                    max={state.producto.numStock}
                    onChange={(e) =>
                      dispatch({
                        type: actionType.SET_CANTIDAD,
                        cantidad: e.target.value,
                      })
                    }
                  ></input>
                  <button
                    className="btn btn-primary btnAddProduct"
                    onClick={handleEventClickregistrarCotizacion}
                  >
                    Añadir al Carrito
                  </button>
                </div>
                {state.mensajeStock === "" ? (
                  ""
                ) : (
                  <span className="producto-mensaje-stock">
                    {state.mensajeStock}
                  </span>
                )}
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="producto-det-row-content">
        {/*<div className="producto-det-row2-content">
          <div className="titulo">
            <span>Detalles del producto</span>
          </div>
          <div className="titulo-border"></div>
          {state.producto.listaProductoDetalle}
        </div>*/}
        <div className="producto-det-row2-content">
          <div className="producto-det-row2-info">
            {InfoCondicionCompra.EMISION}
          </div>
          <div className="producto-det-row2-info">
            {InfoCondicionCompra.STOCK}
          </div>
          <div className="producto-det-row2-info">
            {InfoCondicionCompra.TRANSPORTE}
          </div>
          <div className="producto-det-row2-info">
            {InfoCondicionCompra.DEVOLUCIONES}
          </div>
        </div>
      </div>
      {state.server.success === SUCCESS_SERVER.SUCCES_SERVER_INFO ? (
        <div>
          <br />
          <span className="alert alert-warning" role="alert">
            {state.server.error}
          </span>
        </div>
      ) : (
        ""
      )}
      {state.server.success === SUCCESS_SERVER.SUCCES_SERVER_ERROR ? (
        <div>
          <br />
          <span className="alert alert-danger" role="alert">
            Lo sentimos el recurso no esta disponible, estamos trabajando para
            solucionar el inconveniente.
          </span>
        </div>
      ) : (
        ""
      )}

  <Modal show={showError} onHide={() => setShowError(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            {errorMessage}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowError(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
            Gracias por tu propuesta en breves minutos nuestros asesores se pondrán en contacto contigo para brindarte mayor información
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccess(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showHomeDelivery} onHide={() => setShowHomeDelivery(false)}>
        <Modal.Header closeButton>
        <div className="modal-title h6"><i className="fa fa-truck fa-flip-horizontal"></i> Despacho a domicilio</div>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mt-2" >
            <select id="depId" className="form-control" value={departamento} onChange={handleDepartamentoChange} >
              <option value="">--seleccione--</option>
            {departamentos}
          </select>

          </div>
          <div className="input-group mt-2">
          <select className="form-control" value={provincia} onChange={handleProvinciaChange}  >
              <option value="">--seleccione--</option>
            {provincias}
          </select>
          </div>
          <div className="input-group mt-2">
          <select className="form-control" value={distrito} onChange={handleDistritoChange}  >
              <option value="">--seleccione--</option>
            {distritos}
          </select>
          </div>
          <div className="input-group mt-2 justify-content-center">
          <button
                className="btn btn-primary btnAvailable"
                onClick={() =>{ setShowHomeDelivery(false); setShowHomeDeliveryLocation(true); setSelectedOption('')}}
                disabled={!isDisponibilidadEnabled}
              >
                Ver Disponibilidad
              </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showHomeDeliveryLocation} onHide={() => setShowHomeDeliveryLocation(false)}>
        <Modal.Header closeButton>
        <div className="modal-title h6"><i className="fa fa-truck fa-flip-horizontal"></i> Despacho a domicilio</div>
        </Modal.Header>
        <Modal.Body>
        <div className="body-location">
          <div className="location-selection">
            <i className="location-icon fa fa-location-arrow"></i>
            <p className="location-name-prefix">A</p>
            <p className="location-name">{distritoSeleccionado}</p>
            <div className="change-location-container ">
              <i className="change-location-icon fa fa-pencil"></i>
              <a className="change-location-button" onClick={() =>{  setShowHomeDeliveryLocation(false);setShowHomeDelivery(true);}}>Cambiar</a>
            </div>
          </div>
          <div className="data-section">
            <h1 className="">Opciones de envío:</h1>
            { departamento === '15' && provincia === '01' ?
            <div className="data-body">
              <div className="data-list">
               {/* <div className="data-entity">
                  <i className="check-icon fa fa-circle"></i>
                  <p className="data-title">Llega en 24 horas - USD 5.90</p>
                </div>*/}
                <div className="data-entity">
                  <i className="check-icon fa fa-circle"></i>
                  <p className="data-title">Llega en 72 horas - USD 5.90</p>
                </div>
                <p className="data-subtitle">Si tu compra es mayor o igual a USD 80.00 el envìo es gratis en respuestos pequeños.</p>
                <p className="data-subtitle">La disponibilidad puede variar.</p>
                </div>
            </div>
            : <>No existe cobertura para este distrito</>}
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowHomeDeliveryLocation(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWhatsapp} onHide={() => setShowWhatsapp(false)}>
        <Modal.Header closeButton>
        <div className="modal-title h6"><i className="fa fa-home"></i>Envío de Mensaje a Whatsapp</div>
        </Modal.Header>
        <Modal.Body>
        <span>Hola me interesa el articulo {state.producto?.vchDescripcion} el precio no se acomoda a mi presupuesto y estoy dispuesto a pagar la suma de USD$</span> 
              <Form.Control
                type="number"
                name="message"
                onChange={handleChangeWSP}
                placeholder="Ingresa el nuevo importe"
                required
              />
              <span>Inc Igv</span>
        </Modal.Body>
        <Modal.Footer>
        <a className={!msgWsp ? " btn disabled":" btn btn-primary"} style={{color: "white",}}
                href={`https://api.whatsapp.com/send?phone=5116307605&text=${msgWsp}`}
                target="noreferrer">   Enviar </a>
        </Modal.Footer>
  
      </Modal>

      
      <Modal show={showPickupStore} onHide={() => setShowPickupStore(false)}>
        <Modal.Header closeButton>
        <div className="modal-title h6"><i className="fa fa-home"></i> Recojo en un punto</div>
        </Modal.Header>
        <Modal.Body>
          <label style={{fontSize:"14px", color: "red"}}>Entre 24 y 72 horas le avisaremos cuando su artículo este llegando al punto de entrega seleccionado.</label>
        <div className="data-section" style={{marginTop:"0px"}}>
            <h1 className="">Nuestros puntos de retiro son:</h1>
            <div className="data-body">
              <div className="data-list">
                <div className="data-entity" style={{display:"table-column"}}>
                  <p className="data-title">Av Republica de Panama 4259 - Surquillo <input

                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={handleOptionChange}
                  /></p>
                  <p className="data-subtitle" style={{marginLeft:"12px",marginTop:"-17px"}}>A partir del {formatDate(new Date())}</p>
                  
                </div>
                
                <div className="data-entity" style={{display:"table-column"}}>
                <p className="data-title">Av Libertad 386 - San Miguel <input

                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={handleOptionChange}
                  /></p>
                <p className="data-subtitle" style={{marginLeft:"12px",marginTop:"-17px"}}>A partir del {formatDate(addDays(new Date(), 2))}</p>
                
                </div>
                
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Solicitud de Descuento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          {isDisabled &&<> Desea <Link to={{
              pathname: '/loginCliente',
              state: { from: location.pathname },
            }}>
              loguearse</Link> o continuar como <a href="#" onClick={handleInvitado}>invitado</a></>}
            
            <Form.Group controlId="productId">
              <Form.Control
                type="hidden"
                name="productId"
                value={formData.productId}
                placeholder="Ingresa tu Nombre"
              />
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>Nombre y/o Apellido</Form.Label>
              <Form.Control
                type="text"
                name="name"
                disabled={isDisabled}
                value={formData.name}
                onChange={handleChange}
                placeholder="Ingresa tu Nombre"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                name="email"
                disabled={isDisabled}
                value={formData.email}
                onChange={handleChange}
                placeholder="Ingresa tu email"
                isInvalid={!!errors.email}
                required
              />
            </Form.Group>
            <Form.Group controlId="formAsunto">
              <Form.Label>Asunto</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                disabled={isDisabled}
                value={formData.subject}
                onChange={handleChange}
                placeholder="Ingresa el asunto"
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Mensaje</Form.Label>         
              <br/>
             <span>Hola me interesa el articulo {state.producto?.vchDescripcion} el precio no se acomoda a mi presupuesto y estoy dispuesto a pagar la suma de USD$</span> 
              <Form.Control
                type="text"
                name="message"
                disabled={isDisabled}
                value={formData.message}
                onChange={handleChange}
                isInvalid={!!errors.message}
                placeholder="Ingresa el nuevo importe"
                required
              />
              <span>Inc Igv</span>
            </Form.Group>
            <p>{errors.email}{" "}{errors.message}</p>
            <Button variant="primary" type="submit" disabled={!!errors.email  || !!errors.message  || isSending}>
              {isSending ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Enviando...
                </>
              ) : (
                'Enviar'
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-direccion"
        show={state.showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={handleEventCloseModal}>
          <Modal.Title id="contained-modal-title-vcenter">
            <i className="fa fa-check fa-producto-detalle"></i> Producto añadido
            correctamente a su carrito de compra
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-body-modal">
            <div className="producto-det-col1-data">
              <img
                src={state.producto.imagenDefault.chrSrcImagen}
                alt={state.producto.vchDescripcion}
              ></img>
            </div>
            <div className="producto-det-col2-data">
              <div className="producto-det-data-item">
                <span className="label-item-desc">
                  {state.producto.vchDescripcion}
                </span>
              </div>
              {(state.producto.typePresentacion == TypePresentacion.TypeRemate) ? <>
                <div className="producto-det-data-item">
                <label className="label-item">Precio:</label>
                <span>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda" style={{textDecoration: "line-through"}}>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? state.producto.numValorVentaRefDolar
                    : state.producto.numValorVentaSoles}
                </label>
              </div></>:<></>}
              
              <div className="producto-det-data-item">
              {state.producto.typePresentacion == TypePresentacion.TypeRemate ?  
             <> <label className="label-item" style={{color: "#FFA500"}}>Precio Ocasión:</label>
                <span style={{color: "#FFA500"}}>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda" style={{color: "#FFA500", fontWeight: "bold"}}>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? state.producto.numValorVentaDolar
                    : state.producto.numValorVentaSoles}
                </label></> : 
              <><label className="label-item">Precio:</label>
                <span>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda">
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? state.producto.numValorVentaDolar
                    : state.producto.numValorVentaSoles}
                </label>
                </>}
               
        

              </div>
              <div className="producto-det-data-item">
                <label className="label-item">Cantidad:</label>
                <span>
                  {state.cotizacionResumen.cantidadDetalleSeleccionado}
                </span>
              </div>
              <div className="producto-det-data-item">
                <span className="label-item-total">
                  Hay {state.cotizacionResumen.totalRegistros} artículos en su
                  carrito.
                </span>
              </div>
              <div className="producto-det-data-item">
                <label className="label-item">Subtotal:</label>
                <span>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda">
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? state.cotizacionResumen.numSubTotalDol
                    : state.cotizacionResumen.numSubTotalSol}
                </label>
              </div>
              <div className="producto-det-data-item">
                <label className="label-item">Igv:</label>
                <span>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda">
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? state.cotizacionResumen.numIgvDol
                    : state.cotizacionResumen.numIgvSol}
                </label>
              </div>
              <div className="producto-det-data-item">
                <label className="label-item">Envío:</label>
                <span>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda">{"00.00"}</label>
              </div>
              <div className="producto-det-data-item">
                <label className="label-item">Total:</label>
                <span>
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? Moneda.DOLARES.codigoIso4217
                    : Moneda.SOLES.codigoIso4217}{" "}
                </span>
                <label className="label-moneda">
                  {state.producto.numCodigoMoneda ===
                    Moneda.DOLARES.numCodigoMoneda
                    ? state.cotizacionResumen.numTotalDol
                    : state.cotizacionResumen.numTotalSol}
                </label>
              </div>
            </div>
            <div className="producto-det-col3-data"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleEventCloseModal}>
            CONTINUAR COMPRANDO
          </button>
          <button className="btn btn-primary" onClick={handleEventGoCaja}>
            <i className="fa fa-check"></i>
            PASAR POR CAJA
          </button>
        </Modal.Footer>
      </Modal>
      <ServerException server={state.server}></ServerException>
    </div>
  );
}

let actionType = {
  LOAD_PRODUCTOS: "LOAD_PRODUCTOS",
  SET_CANTIDAD: "SET_CANTIDAD",
  SET_CANTIDAD_STOCK: "SET_CANTIDAD_STOCK",
  ERROR: "ERROR",
  SHOW: "SHOW",
  ZOOM_OBJECT: "ZOOM_OBJECT",
};
const reducer = (state, action) => {
  switch (action.type) {
    case actionType.LOAD_PRODUCTOS:
      return {
        ...state,
        producto: action.producto,
        server: action.server,
        shareFacebook: action.shareFacebook,
        shareTwitter: action.shareTwitter,
      };
    case actionType.ERROR:
      return {
        ...state,
        server: action.server,
      };
    case actionType.ZOOM:
      return {
        ...state,
        zoom: action.zoom,
      };
    case actionType.ZOOM_OBJECT:
      return {
        ...state,
        image: action.image,
        zoom: action.zoom,
      };
    case actionType.SET_CANTIDAD:
      return {
        ...state,
        cantidad: action.cantidad,
      };
    case actionType.SET_CANTIDAD_STOCK:
      return {
        ...state,
        cantidad: action.cantidad,
        mensajeStock: action.mensajeStock,
      };
    case actionType.SHOW:
      return {
        ...state,
        showModal: action.showModal,
        cotizacionResumen: action.cotizacionResumen,
      };
    default:
      return state;
  }
};
